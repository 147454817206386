

































import Bootstrapper from '@/app_code/Bootstrapping/Bootstrapper'
import { GetResourceModel } from '@/app_code/models/GetResourceModel'
import { API, ServiceManager } from '@/app_code/services'
import { View } from '@/app_code/services/meta/view'
import ServiceType from '@/app_code/services/ServiceTypes'
import { Component, Mixins } from 'vue-property-decorator'
import Topic from '../components/Topic.vue'

@Component({
  components: {
    Topic
  }
})
export default class ResourceDetails extends Mixins(View) {
  private resource: GetResourceModel | null = null

  private readonly resourceId = this.$route.params.id

  private api = ServiceManager.Require<API>(ServiceType.API)

  mounted (): void {
    this.getResourcePost()
  }

  private getImageUrl (imageId: string): string {
    return `${Bootstrapper.Config?.API.Base}/api/files/${imageId}/download`
  }

  private getResourcePost (): Promise<boolean> {
    return new Promise((resolve, reject) =>
      this.api.ResourceAPI.GetResource(this.resourceId)
        .then(data => {
          this.resource = data
          resolve(true)
        })
        .catch(reject)
    )
  }
}
